import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/less/index.less'
import { MdvUI } from './components/index'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/element-skin/index.css'
import httpService from './ddd/http.service'
import localStorageService from './ddd/localStorage.service'
import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import tool from "./utils/too";
Vue.use(tool);

Vue.use(VideoPlayer)

Vue.use(ElementUI)
Vue.use(MdvUI)

Vue.config.productionTip = false

Vue.http = Vue.prototype.$http = httpService
Vue.local = Vue.prototype.$local = localStorageService

Vue.sleep = Vue.prototype.$sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
}

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')