const env = {
    // API
    mdApiBase: '/video-dev',
    // mdApiBase: 'https://230ck94613.picp.vip/video-dev',
    // 资源文件
    mdCdnBase: '/cdn',
    // 模版、绑定微信
    mdTempBase: 'https://wx.dxvote.cn',
    mdWxBase: 'https://wx.dxvote.cn',
    // 专题活动
    mdZhuanTiBase: 'https://wx.dxvote.cn/wxsubject'
};
export const mdApiBase = env.mdApiBase
export const mdCdnBase = env.mdCdnBase
export const mdTempBase = env.mdTempBase
export const mdWxBase = env.mdWxBase
export const mdZhuanTiBase = env.mdZhuanTiBase