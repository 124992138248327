let local = localStorage;
/** 
 * 本地存储服务
 */
export default {
    /**
     * 通过key获取本地存储
     * @param {*} key 键
     */
    getItem (key) {
        try {
            return JSON.parse(local.getItem(key));
        } catch (error) {
            return null;
        }
    },
    /**
     * 设置本地存储
     * @param {*} key 键
     * @param {*} value 值
     */
    setItem (key, value) {
        local.setItem(key, JSON.stringify(value))
    },
    /**
     * 清楚所有本地存储
     */
    clear () {
        local.clear()
    },
    /**
     * 通过key删除本地存储
     * @param {*} key 键
     */
    removeItem (key) {
        local.removeItem(key)
    },
}