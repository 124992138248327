import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style

Vue.use(VueRouter);

const list = [
    // 个人中心
    {
        path: '/manage',
        name: 'manage',
        component: () =>
            import ('../shared/manage/index.vue'),
        redirect: '/manage/guankanjilu',
        children: [{
                path: 'guankanjilu',
                name: '观看记录',
                meta: {
                    title: '观看记录',
                    keepalive: false,
                    path: '/manage/guankanjilu'
                },
                component: () =>
                    import ('../pages/manage/guankanjilu/index.vue')
            },
            {
                path: 'shoucangjilu',
                name: '收藏记录',
                meta: {
                    title: '收藏记录',
                    keepalive: false,
                    path: '/manage/shoucangjilu'
                },
                component: () =>
                    import ('../pages/manage/shoucangjilu/index.vue')
            },
            {
                path: 'datijilu',
                name: '答题记录',
                meta: {
                    title: '答题记录',
                    keepalive: false,
                    path: '/manage/datijilu'
                },
                component: () =>
                    import ('../pages/manage/datijilu/index.vue')
            },
            {
                path: 'gerenziliao',
                name: '个人资料',
                meta: {
                    title: '个人资料',
                    keepalive: false,
                    path: '/manage/gerenziliao'
                },
                component: () =>
                    import ('../pages/manage/gerenziliao/index.vue')
            },
            {
                path: 'zhengshu',
                name: '酷学证书',
                meta: {
                    title: '酷学证书',
                    keepalive: false,
                    path: '/manage/zhengshu'
                },
                component: () =>
                    import ('../pages/manage/zhengshu/index.vue')
            }
        ]
    },
    // 官网
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/home',
        children: [{
            path: 'home',
            name: '首页',
            meta: {
                title: '首页',
                keepalive: false
            },
            component: () =>
                import ('../pages/home.vue')
        }]
    },
    {
        path: '/cas',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/home',
        children: [{
            path: 'home',
            name: '首页',
            meta: {
                title: '首页',
                keepalive: false
            },
            component: () =>
                import ('../pages/home.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/search',
        children: [{
            path: 'search',
            name: '搜索',
            meta: {
                title: '搜索',
                keepalive: false
            },
            component: () =>
                import ('../pages/search.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/download',
        children: [{
            path: 'download',
            name: '下载',
            meta: {
                title: '下载',
                keepalive: false
            },
            component: () =>
                import ('../pages/download.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/category',
        children: [{
            path: 'category',
            name: '酷学课程',
            meta: {
                title: '酷学课程',
                keepalive: false
            },
            component: () =>
                import ('../pages/category.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/topics',
        children: [{
            path: 'topics',
            name: '酷学题库',
            meta: {
                title: '酷学题库',
                keepalive: false
            },
            component: () =>
                import ('../pages/topics.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/topicDetail',
        children: [{
            path: 'topicDetail',
            name: '题目列表',
            meta: {
                title: '题目列表',
                keepalive: false
            },
            component: () =>
                import ('../pages/topDetails.vue')
        }]
    },

    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/topicDetailCheck',
        children: [{
            path: 'topicDetailCheck',
            name: '查看题目',
            meta: {
                title: '查看题目',
                keepalive: false
            },
            component: () =>
                import ('../pages/topicDetailChecks.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/courseDetail',
        children: [{
            path: 'courseDetail',
            name: '课程详情',
            meta: {
                title: '课程详情',
                keepalive: false
            },
            component: () =>
                import ('../pages/courseDetail.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/video',
        children: [{
            path: 'video',
            name: '视频',
            meta: {
                title: '视频',
                keepalive: false
            },
            component: () =>
                import ('../pages/video.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/news',
        children: [{
            path: 'news',
            name: '酷学资讯',
            meta: {
                title: '酷学资讯',
                keepalive: false
            },
            component: () =>
                import ('../pages/news.vue')
        }]
    }, {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/encyclopedia',
        children: [{
            path: 'encyclopedia',
            name: '酷学百科',
            meta: {
                title: '酷学百科',
                keepalive: false
            },
            component: () =>
                import ('../pages/encyclopedia.vue')
        }]
    },{
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/VR',
        children: [{
            path: 'vr',
            name: '酷学VR',
            meta: {
                title: '酷学VR',
                keepalive: false
            },
            component: () =>
                import ('../pages/vr.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/newsDetail',
        children: [{
            path: 'newsDetail',
            name: '资讯详情',
            meta: {
                title: '资讯详情',
                keepalive: false
            },
            component: () =>
                import ('../pages/newsDetail.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/live',
        children: [{
            path: 'live',
            name: '酷学直播',
            meta: {
                title: '酷学直播',
                keepalive: false
            },
            component: () =>
                import ('../pages/live.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/teachers',
        children: [{
            path: 'teachers',
            name: '酷学名师',
            meta: {
                title: '酷学名师',
                keepalive: false
            },
            component: () =>
                import ('../pages/teachers.vue')
        }]
    },
    {
        path: '/',
        name: 'shared',
        component: () =>
            import ('../shared/index.vue'),
        redirect: '/livevideo',
        children: [{
            path: 'livevideo',
            name: '直播回放',
            meta: {
                title: '直播回放',
                keepalive: false
            },
            component: () =>
                import ('../pages/livevideo.vue')
        }]
    },
    {
        path: '/login',
        name: '登录',
        meta: {
            title: '登录',
            keepalive: false
        },
        component: () =>
            import ('../pages/login.vue')
    },
    {
        path: '/register',
        name: '注册',
        meta: {
            title: '注册',
            keepalive: false
        },
        component: () =>
            import ('../pages/register.vue')
    },
    {
        path: '/forget',
        name: '忘记密码',
        meta: {
            title: '忘记密码',
            keepalive: false
        },
        component: () =>
            import ('../pages/forget.vue')
    }
];

const routes = [...list];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
})

// 防止重复点击路由
const VueRouterPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

/**
 * 
 * @param to
 * @param from
 * @param next()
 */
router.beforeEach((to, from, next) => {
    NProgress.start();

    next()
})

/**
 * 
 * @param to
 * @param from
 */
router.afterEach(() => {
    NProgress.done();
})

export default router