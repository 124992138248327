import Vue from 'vue'
import Vuex from 'vuex'
import { mdWxBase } from '../ddd/config.service'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        specialRouters: ['/video', '/topicDetail', '/topicDetailCheck', ],
        isLogin: false, // 是否登录
        user: {
            dept: {
                deptId: null,
                deptName: ''
            }
        }, // 用户信息
        fixed: true, // 顶部导航是否固定
        showBanner: true, // 是否显示banner图
        // 活动相关参数
        activity: {
            // 默认活动介绍
            defaultTemplateContent: '<h2><strong>活动主题</strong></h2><p style="text-align: justify;"><span style="color: rgb(0, 0, 0);">为庆祝XXXX节，弘扬XXXX精神，由XXXX主办的XXXX评选活动。</span></p><p style="text-align: justify;">​<br></p><h2><strong>活动时间</strong></h2><p style="text-align: justify;">报名时间：</p><p style="text-align: justify;"><span style="color: rgb(0, 0, 0);">2020-08-10 09:00至2020-08-20 20:00</span></p><p style="text-align: justify;">投票时间：</p><p style="text-align: justify;"><span style="color: rgb(0, 0, 0);">2020-08-14 09:00至2020-08-20 20:00</span></p><p style="text-align: justify;"><br></p><h2><strong>参赛对象</strong></h2><p style="text-align: justify;"><span style="color: rgb(0, 0, 0);">XXXXXXXX</span></p><p style="text-align: justify;"><br></p><h2><strong>活动流程</strong></h2><p style="text-align: justify;"><span style="color: rgb(0, 0, 0);">第一阶段：报名阶段</span></p><p style="text-align: justify;"><span style="color: rgb(0, 0, 0);">报名时间：8月10日-8月13日 </span></p><ul><li>点击投票页“立即报名”按钮即可报名；</li><li>禁止上传任何非法、侵权内容。</li></ul><p style="text-align: justify;"><span style="color: rgb(0, 0, 0);">第二阶段：投票阶段</span></p><p style="text-align: justify;"><span style="color: rgb(0, 0, 0);">投票时间：8月14日-8月20日（20日20：00结束投票）</span></p><p style="text-align: justify;"><br></p><h2><strong>评选规则</strong></h2><ul><li>投票规则：每个微信每天可投1票，只能为同一选手投1票</li><li>投票声明：严禁任何作弊行为，系统会进行自动监测，一经发现立即取消投票资格</li></ul><p style="text-align: justify;"><br></p><p style="text-align: center;"><span style="color: rgb(0, 0, 0);">本活动最终解释权归XXX所有</span></p><p><br></p>',
            // 基本信息
            jibenxinxiParams: {
                id: 0, // 活动编号
                title: '默认活动标题(创建后请修改)', //活动名称
                beginTime: null, //活动开始时间
                endTime: null, //活动结束时间
                beginEndTime: [], // 活动开始结束时间
                bulletin: 1, // 是否开启公告 0是 1否
                bulletinContent: null, // 公告内容
                intro: null, // 活动介绍
                template: 0,
                showImg: null, // 展示图
                remark: null, // 活动H5二维码
            },
            // 页面设置
            yemianshezhiParams: {
                id: 0,
                activityId: 0,
                // 轮播图
                carousels: [],
                templateId: null, // 活动模板
                templateImage: null, // 活动模板
                colors: '#1384ED', // 配色方案
                backImgId: null, // 背景图片编号
                backImg: null, // 背景图片
                frameId: null, // 边框编号
                frame: null, // 边框
                flotageId: null, // 漂浮物编号
                flotage: null, // 漂浮物
                flotageDirection: null, // 漂浮物方向
                backMusicId: null, // 背景音乐编号
                backMusic: null, // 背景音乐
                backMusicName: null, // 背景音乐名称
                // ======
                groupColumn: '4', // 分组列数
                playerColumn: '2', // 选手列数
                playerImg: '1', // 选手图片
                playerSort: '1', // 选手排序
                votingPageMul: [], // 页面显示：投票页面
                activityInfoMul: [], // 页面显示：活动介绍
                playerDetailMul: [], // 页面显示：选手详情
                sortList: [], // 页面显示：排行榜
                playerName: '选手', // 选手名称
                playerUnit: '个', //选手单位
                buttonTitle: '投票', //按钮名称
                ticketUnit: '票', // 票数单位
                ticketButtonName: '拉票', // 拉票按钮名称
                playerImgSel: '1', // 选手图片单选
                // ======
                bottomCopyrightSel: '1', // 底部版权
                bottomCopyrightTitle: null, // 底部版权自定义
                bottomMenu: ['2'], // 底部菜单
                bottomMenuJson: null, // 底部自定义菜单
                homeSet: '2', // 设置首页
                icon: null,
                name: null,
                url: null,
                isCustom: true
            },
            // 投票设置
            toupiaoshezhiParams: {
                id: 0,
                activityId: 0,
                rangeType: 1, // 周期设置 1=固定次数 2=周期次数
                rangeNumber: 1, // 票数
                selectRule: 1, // 勾选规则 1=单选 2=多选
                selectRuleMin: 1, // 勾选规则 最少
                selectRuleMax: 3, // 勾选规则 最多
                field4: null, // 公众号授权
                attentionApply: 1, // 关注后报名
                attentionTicket: 1, // 关注后投票
                // ======
                authCode: 0, // 验证码
                aiRefresh: 0, // 智能防刷
                areaRange: null, // 可投票地区
                areaRangeCode: [], // 可投票地区
                blankSel: 3, // 黑名单防刷 1=关闭、2=低、3=中、4=高
                timeRange: 1, // 投票时间限制
                timeRangeBegin: null,
                timeRangeEnd: null,
                timeRangeBeginEnd: ['08:40', '09:40'], // 投票时间
                banIp: null // 封禁IP
            },
            // 报名设置
            baomingshezhiParams: {
                id: 0,
                activityId: 0,
                openLine: 1, // 在线报名 integer 0true 1false
                openVedio: 1, // 视频报名 integer 0true 1false
                openImg: 0, // 图片报名 integer 0true 1false
                openImgName: '图片', // 图片名称 string
                openImgMin: 0, // 图片最小张数 integer
                openImgMax: 1, // 图片最大张数 integer
                formInfo: [{
                        id: 1,
                        label: '姓名',
                        inputType: 1, // 1单行文本、2多行文本、3数字、4年龄、5电话、6超链接、7地区、8图片、9身份证
                        inputStatus: 1, // 1选填、2必填、3隐藏
                        inputPublic: false, // true公开、false不公开
                        inputDefault: true // 默认单行文本
                    }, {
                        id: 2,
                        label: '简介',
                        inputType: 1, // 1单行文本、2多行文本、3数字、4年龄、5电话、6超链接、7地区、8图片、9身份证
                        inputStatus: 1, // 1选填、2必填、3隐藏
                        inputPublic: false, // true公开、false不公开
                        inputDefault: true // 默认单行文本
                    }] // 报名表单 string
            },
            // 高级设置
            gaojishezhiParams: {
                id: 0,
                activityId: 0,
                screenStatus: '0', // 开屏功能开关 string 0true 1false
                selType: 1, // 开屏广告（1、开屏广告  2、开屏动画） integer
                advertising: null, // 开屏广告 string
                animation: null, // 开屏动画图片 string
                button: null, // 按钮样式图片 string
                alertAdvertising: 0, // 弹窗广告开关 integer 0true 1false
                alertImg: null, // 弹窗广告图片 string
                alertUrl: null, // 弹窗广告网址 alertUrl
                underAdvertising: 1, // 底部广告开关 integer 0true 1false
                underImg: null, // 底部广告图片 string
                underUrl: null, // 底部广告网址 string
                // ======
                shareImg: null, // 分享图片 string
                activityTitle: null, // 活动分享标题 string
                playerTitle: null, // 选手分享标题 string
                shareContent: null, // 分享内容 string
                // ======
                sceneStatus: '1', // 现场大屏开关 string 0true 1false
                sceneName: null, // 大屏活动名称 string
                showNumber: 20, // 选手数 integer
                showStyle: 1 // 大屏风格 integer 1通用、2科技、3财务
            }
        },
        templateURL: `${mdWxBase}/temp1/pages/index/index?id=`, // 模板链接
        // 个人中心左侧菜单
        manageMenu: [],
        // 创建活动左侧菜单
        manangeActivityMenu: [],
        // 创建模板左侧菜单
        manangeTemplateMenu: []
    },
    mutations: {},
    actions: {},
    modules: {}
})